form[name="sylius_checkout_address"] {
    .btn {
        min-width: 11.25rem;
        border-radius: $border-radius-lg;
        padding: 1rem 1.25rem;
        font-size: 0.75rem;
        font-weight: 800;
        word-break: break-word;
    }

    #sylius-billing-address, #sylius-shipping-address {
        margin-bottom: 3.75rem;

        h3 {
            font-size: 2.188rem;
            font-weight: 700;
            color: $gray-900;
            margin-bottom: 1.25rem;
        }

        label {
            display: block;
        }

        .form-control, select {
            height: 2.813rem;
            padding: 0 0.938rem;
            background-color: $white;
            border: 0.063rem solid $gray-425;
            display: flex;
            align-items: center;
            width: 100%;
            color: $gray-500;
            font-size: 0.75rem;

            &::placeholder {
                color: $gray-500;
            }
        }

        .checkbox {
            min-height: 1.188rem;
            display: flex;
            align-items: flex-start;
            margin-bottom: 1.25rem;
            flex-wrap: wrap;
            width: 100%;
            cursor: pointer;
            input[type="checkbox"] {
                width: 1.188rem;
                height: 1.188rem;
                border: 0.188rem solid $white;
                outline: 0.125rem solid $gray-400;
                margin: 0;
                cursor: pointer;
                vertical-align: top;
                background-color: #fff;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                appearance: none;

                &:checked {
                    background-color: $pink;
                    border-color: $white;
                    outline: 0.125rem solid $gray-400;
                    background-image: none !important;
                }

                &:focus, &:active {
                    outline: 0.125rem solid $gray-400 !important;
                }
                &:focus-visible{
                    outline-offset: 0 !important;
                }
            }

            label {
                font-size: 0.75rem;
                letter-spacing: 0.125rem;
                color: $gray-600;
                width: calc(100% - 1.75rem);
                padding-left: 0.625rem;
                cursor: pointer;
                font-weight: 800;
                text-transform: uppercase;
                word-break: break-word;
            }
        }
        .different-shipping-address{
            .field-wrap{
                display: inline-block;
            }
            .checkbox{
                margin-bottom: 0;
            }
        }
        div:not(.reverse-checkbox){
            .checkbox{
                label{
                    width: auto;
                }
            }
        }
    }
}
#sylius-checkout-subtotal{
    .single-item{
        padding-bottom: 2.25rem;
        border-bottom: 0.063rem solid $gray-425;
        margin-bottom: 0.875rem;
        .image-wrap{
            width: 2.5rem;
            height: 2.5rem;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }
        p{
            font-size: 0.75rem;
        }
    }
    .subtotal{
        border-bottom: 0.063rem solid $gray-425;
        margin-bottom: 4.5rem;
    }
    .total{
        margin-bottom: 2rem;
    }
    .subtotal, .total{
        p{
            font-size: 0.75rem;
            color: $gray-500;
            strong{
                color: $black;
                font-weight: 700;
            }
            &.big{
                font-size: 1rem;
                line-height: 1;
            }
            &.bg-primary{
                padding: 0.5rem 0.75rem 0.5rem 1rem;
            }
        }
    }
    .summary-btn{
        padding: 1.25rem;
        line-height: 1;
        font-weight: 700;
        font-size: 0.75rem;
        min-width: 11.563rem;
    }
}
form[name="sylius_checkout_complete"]{
    .addresses{
        h3{
            font-size: 1.5rem;
            font-weight: 700;
            color: $gray-900;
        }
        .address{
            border: 0.063rem solid $gray-425;
            padding: 1rem 1.25rem;
            min-height: 12.5rem;
        }
        .address, address, p{
            font-size: 1rem;
            color: $black;
        }
        strong{
            font-weight: 700;
        }
    }
    #sylius-order{
        border-bottom: 0.063rem solid $gray-425;
        padding-bottom: 1rem;
        margin-bottom: 1.5rem;
        > div{
            padding: 1rem 0;
            &:not(.totals) {
                border-bottom: 0.063rem solid #d9d9d9;
            }
        }
        .image-wrap{
            margin-right: 2.25rem;
            width: 3.75rem;
            height: 3.75rem;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }
        .content{
            width: calc(100% - 6rem);
        }
        .sylius-product-name, .sylius-product-variant-code{
            font-size: 0.75rem;
        }
        .item-quantity{
            p {
                font-size: 0.75rem;
                line-height: 1;
                color: $gray-500;
                padding: 0.5rem;
            }
        }
        .totals{
            p{
                font-size: 0.75rem;
                line-height: 1;
                color: $gray-500;
                padding: 0.5rem;
                strong{
                    color: $black;
                    font-weight: 700;
                }
            }
        }
    }
}
.navigation{
    margin-top: 5.625rem;
    .btn{
        padding: 1.25rem;
        line-height: 1;
        font-weight: 700;
        font-size: 0.75rem;
        min-width: 11.563rem;
    }
}
#sylius-shipping-methods form, #sylius-payment-methods form, form[name="sylius_checkout_select_payment"]{
    .header{
        font-size: 1.25rem;
        color: $black;
        font-weight: 700;
        margin-bottom: 1.25rem;
    }
    .items{
        .item{
            display: flex;
            margin-bottom: 0.5rem;
            font-size: 1.125rem;
            cursor: pointer;
            label{
                cursor: pointer;
            }
            .content{
                margin: 0 0.625rem;
                .header {
                    color: $black;
                    font-size: 1.125rem;
                    font-weight: 700;
                    margin-bottom: 0;
                }
            }
            .extra {
                margin-left: auto;
                .label {
                    color: $black;
                }
            }
        }
    }
}
.order-header{
    font-size: 1.5rem;
    .list{
        font-size: 1.25rem;
    }
}
#sylius-pay-link{
    min-width: 11.25rem;
}
@include media-breakpoint-up(md) {
    form[name="sylius_checkout_address"] {
        .btn {
            padding: 1.25rem 2.25rem;
        }
    }
    #sylius-billing-address, #sylius-shipping-address {
        .checkbox {
            justify-content: flex-end;
        }
        .reverse-checkbox{
            .checkbox {
                justify-content: flex-start !important;
            }
        }
    }
    #sylius-checkout-subtotal{
        .single-item{
            .info{
                width: calc(100% - 3.5rem);
            }
        }
        .subtotal, .total{
            > div{
                padding-left: 0.25rem;
                padding-right: 0.75rem;
            }
        }
    }
    #sylius-shipping-methods{
        form{
            .items{
                .item{
                    .extra {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .order-header{
        font-size: 2rem;
        .list{
            font-size: 1.5rem;
        }
    }
    form[name="sylius_checkout_complete"] {
        .addresses {
            .address {
                min-height: 15rem;
            }
        }
        #sylius-order{
            .totals{
                .totals-wrap{
                    width: 50%;
                }
            }
            .item-quantity{
                p{
                    padding: 0;
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    form[name="sylius_checkout_complete"] {
        .addresses {
            h3 {
                font-size: 1.875rem;
            }
            .address {
                min-height: 12.5rem;
            }
        }
        #sylius-order{
            padding-bottom: 2.5rem;
            margin-bottom: 3.75rem;
            > div {
                padding: 1.5rem 0;
            }
            .totals{
                p{
                    font-size: 1rem;
                    padding: 0.5rem 0.75rem 0.5rem 1rem;
                }
            }
            .sylius-product-name, .sylius-product-variant-code{
                font-size: 1rem;
            }
            .item-quantity{
                p {
                    font-size: 1rem;
                }
            }
        }
    }
}
.dontforgetabout-addtocart {
    #sylius-product-selecting-variant {
        .quantity {
            .plus, .minus {
                color: $black !important;
                background-color: transparent !important;
            }
            div.required.field {
                display: flex;
                &:after {
                    content: 'szt.';
                    color: $black;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                    font-size: 1rem;
                }
            }
            input {
                border: 0;
                padding: 0;
                color: $black;
                width: 2rem;
                font-size: 1rem;
                text-align: center;
                display: block;
                -moz-appearance: textfield;
                font-weight: bold;
                &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
        }
        .add-to-cart {
            display: none;
        }
    }
}
