.view{
    padding: 0.625rem 1.5rem;
    border-radius: $border-radius-lg;
    height: 3.625rem;
    span{
        font-size: 0.75rem;
        color: $black;
    }
    .single-view{
        width: 2.25rem;
        height: 2.25rem;
        background-color: $gray-100;
        color: $gray-450;
        margin: 0 0.375rem;
        padding: 0;
        &:hover, &.active{
            color: $black !important;
            background-color: $gray-100 !important;
        }
    }
}
@include media-breakpoint-up(xl) {
    .view{
        .single-view{
            width: 1.875rem;
            height: 1.875rem;
            margin: 0 0.125rem;
            font-size: 1rem;
        }
    }
    .view-search-wrap{
        .view{
            max-width: 25%;
            flex: 0 1 25%;
        }
        .search-bar{
            max-width: 75%;
            flex: 0 1 75%;
            padding-left: 1.5rem;
        }
    }
}
@include media-breakpoint-up(xxl) {
    .view{
        .single-view{
            width: 2.25rem;
            height: 2.25rem;
            margin: 0 0.375rem;
            font-size: 1.25rem;
        }
    }
}
