.review-slider-wrap{
    margin: 0 -0.938rem;
    .single-slide{
        padding: 0 0.938rem;
    }
    .review-slider-single{
        border-radius: $border-radius-lg;
        padding: 1.875rem 2.25rem 1.875rem 1.25rem;
        height: 100%;
        h4{
            color: $gray-600;
            font-size: 1rem;
        }
        p{
            color: $gray-600;
            font-size: 0.75rem;
        }
        .wrap{
            p{
                color: $gray-475;
            }
            .rating{
                i{
                    font-size: 0.875rem;
                    color: $gray-250;
                    margin: 0 0.375rem;
                    &.full{
                        color: $yellow;
                    }
                    &:first-child{
                        margin-left: 0;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
                p{
                    margin-left: 0.875rem;
                }
            }
        }
    }
    .review-slider-arrows{
        top: 50%;
        transform: translateY(-50%);
        left: -0.5rem;
        right: -0.5rem;
        z-index: 1001;
        .arrow{
            width: 2.125rem;
            height: 2.125rem;
            font-size: 1.5rem;
            &:hover{
                cursor: pointer;
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .review-slider-wrap {
        .review-slider-arrows {
            left: -0.625rem;
            right: -0.625rem;
            .arrow {
                width: 3.125rem;
                height: 3.125rem;
                font-size: 2.25rem;
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .review-slider-wrap {
        .review-slider-single {
            padding: 1.5rem 1.875rem 1.5rem 1rem;
        }
    }
}
