main{
    padding: 1.25rem 0;
}
.section-heading{
    margin-bottom: 1.875rem;
    strong{
        font-size: 1rem;
        font-weight: 800;
        margin-right: 1.25rem;
    }
    a{
        font-size: 0.75rem;
        color: $primary;
        i{
            margin-left: 0.625rem;
        }
        &:hover{
            opacity: 0.75;
        }
    }
}
.section-listing{
    .glider{
        margin: 0 -0.75rem;
    }
    .single-product-wrap{
        padding: 0 0.75rem;
    }
    .product-slider-wrap{
        height: 27.188rem;
        .single-product-wrap{
            &:not(.promo){
                height: 44.688rem !important;
            }
            &.promo{
                .single-product{
                    height: 27.188rem !important;
                }
            }
        }
    }
    .product-slider-arrows{
        top: 50%;
        transform: translateY(-50%);
        left: -0.5rem;
        right: -0.5rem;
        z-index: 1001;
        .arrow{
            width: 2.125rem;
            height: 2.125rem;
            font-size: 1.5rem;
            &:hover{
                cursor: pointer;
            }
        }
    }
}
.section-listing-row{
    .table{
        th, td{
            min-width: 100px;
        }
        td{
            &.title-cell{
                min-width: 436px;
            }
        }
    }
}
.section-listing-row, .section-listing-list{
    padding: 1.375rem 1.25rem 2rem 1.375rem;
    border-radius: $border-radius-lg;
    .table{
        > :not(:first-child) {
            border-top: 0;
        }
        .icon{
            width: 2.25rem;
            height: 2.25rem;
            background-color: $gray-100;
            color: $black;
        }
        .price{
            border-radius: 0.625rem;
            line-height: 1;
            padding: 0.375rem 0.625rem;
        }
        .divider{
            padding-left: 0.313rem;
            padding-right: 0.313rem;
        }
        th{
            font-size: 0.75rem;
            color: $black;
            vertical-align: middle;
            border-bottom: 0;
            font-weight: 400;
            padding: 0 1.25rem 2.5rem 1.25rem;
        }
        td{
            border-bottom: 0.063rem solid $gray-100;
            padding: 1.25rem;
            vertical-align: middle;
            &.title-cell{
                padding-right: 2.5rem;
                .icon{
                    margin-right: 0.75rem;
                }
                .title{
                    width: calc(100% - 2.75rem);
                }
            }
            a{
                &:not(.icon){
                    font-size: 0.75rem;
                    color: $black;
                }
            }
            &.warranty{
                font-size: 0.75rem;
            }
        }
    }
}
.icon-box{
    margin-bottom: 4.625rem;
    i{
        font-size: 2.5rem;
        line-height: 1;
    }
    p{
        font-size: 1rem;
        color: $black;
        margin-left: 1.25rem;
    }
    &.bold{
        margin-bottom: 2.25rem;
        p{
            font-weight: 700;
        }
    }
    &.type-1{
        .icon-wrap{
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            width: 100%;
            height: 5rem;
            margin-bottom: 1.25rem;
        }
        p{
            margin-left: 0;
            color: $black;
            font-size: 1.125rem;
        }
    }
    &.type-2{
        i{
            font-size: 3.375rem;
        }
        p{
            margin-left: 0;
            color: #2c2c2c;
        }
    }
    &.type-3{
        > div{
            margin-bottom: 2rem;
        }
        i{
            font-size: 2.5rem;
        }
        p{
            margin-left: 0;
            &.title{
                margin-left: 1.25rem;
                color: #2c2c2c;
            }
        }
    }
}
.references{
    .btn-wrap{
        .btn{
            font-size: 0.75rem;
            font-weight: 800;
            padding: 1.25rem 0.875rem;
            line-height: 1.2;
            width: 8.125rem;
        }
    }
}
.single-reference{
    border-radius: $border-radius-lg;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 40%;
    height: 11.938rem;
    .full{
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        padding: 1.875rem 1.125rem;
        border-radius: $border-radius-lg;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        box-shadow: 0 0.625rem 0.313rem 0.313rem rgba(0, 0, 0, 0.07);
        i{
            font-size: 1.5rem;
            margin-bottom: 1.875rem;
        }
        .content{
            h4{
                color: $gray-600;
                font-size: 1rem;
                margin-bottom: 1.25rem;
            }
            p{
                color: $gray-600;
                font-size: 0.75rem;
            }
        }
        hr{
            border-color: $gray-200;
        }
        .btn{
            padding: 0.875rem 1.25rem;
            border-radius: $border-radius-lg;
            font-size: 0.75rem;
            i{
                font-size: 1.625rem;
                margin-right: 0.875rem;
                margin-bottom: 0;
            }
        }
    }
    &:hover{
        .full{
            opacity: 1;
            visibility: visible;
        }
    }
}
.certificates{
    .cert-image{
        height: 5.875rem;
    }
    .btn{
        border-radius: $border-radius-lg;
        font-size: 0.75rem;
        font-weight: 800;
        padding: 0.875rem 1.875rem;
        line-height: 1.2;
        i{
            font-size: 1.875rem;
        }
    }
}
#referenceModal{
    .modal-dialog{
        max-width: 50rem;
    }
    .btn-close{
        margin: 0.5rem 0.5rem 0.5rem auto;
    }
}
.page-content-wrap{
    table{
        display: block;
        tr{
            display: block;
        }
        td{
            display: block;
            padding: 0.5rem;
            word-break: break-word;
        }
    }
    p{
       font-size: 0.875rem;
    }
    ol, ul{
        padding-left: 1.25rem;
        font-size: 0.875rem;
    }
    h1{
        font-size: 1.5rem;
    }
    h2{
        font-size: 1.25rem;
    }
    h3{
        font-size: 1.125rem;
    }
    img{
        max-width: 100%;
        height: auto;
    }
}
@include media-breakpoint-up(md) {
    .main-content-wrapper{
        overflow: hidden;
        &:hover{
            overflow: visible;
        }
    }
    .section-listing{
        .product-slider-arrows{
            left: -1.5rem;
            right: -1.5rem;
            .arrow{
                width: 3.125rem;
                height: 3.125rem;
                font-size: 2.25rem;
            }
        }
    }
    .page-content-wrap{
        p{
            font-size: 1rem;
        }
        ol, ul{
            font-size: 1rem;
        }
        h1{
            font-size: 2.25rem;
        }
        h2{
            font-size: 2rem;
        }
        h3{
            font-size: 1.5rem;
        }
    }
}
@include media-breakpoint-up(xl) {
    .icon-box {
        margin-bottom: 4.625rem;

        i {
            font-size: 2.5rem;
            line-height: 1;
        }

        p {
            font-size: 1rem;
            color: #2c2c2c;
            margin-left: 1.25rem;
        }

        &.bold {
            margin-bottom: 2.25rem;

            p {
                font-weight: 700;
            }
        }

        &.type-2 {
            i {
                font-size: 3.375rem;
            }

            p {
                margin-left: 0;
            }
        }
    }
    .page-content-wrap {
        table {
            display: table;
            tr {
                display: table-row;
            }
            td {
                display: table-cell;
            }
        }
    }
}
