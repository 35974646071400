.navbar{
    height: 3.5rem;
    .navbar-brand{
        max-width: 9.375rem;
    }
    .menu{
        position: absolute;
        top: 3.5rem;
        left: 0;
        width: 100%;
        height: calc(100vh - 3.5rem);
        overflow-y: scroll;
        transform: translateX(-100%);
        transition: all 0.3s ease-in-out;
        z-index: 9999;
        background-color: $white;
        padding: 1.25rem 0.75rem;
        &.active{
            transform: translateX(0);
        }
    }
    .contact{
        i{
            font-size: 1.75rem;
            margin-right: 1.25rem;
        }
        p{
            font-size: 0.75rem;
            font-weight: 500;
        }
        a{
            font-size: 1rem;
            color: $dark;
            &:hover{
                color: $primary;
            }
        }
    }
    .promos{
        .single-promo{
            &:after{
                content: '';
                position: absolute;
                bottom: -1.625rem;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0.594rem 0.625rem 0.594rem;
                border-color: transparent transparent $primary transparent;
                opacity: 0;
                visibility: hidden;
                display: block;
                transition: all 0.3s ease-in-out;
            }
            &:hover, &.active{
                &:after{
                    opacity: 1;
                    visibility: visible;
                }
            }
            &:not(:last-child) {
                margin-right: 0.625rem;
            }
            &.hp{
                width: 9rem;
                height: 5.125rem;
                background: transparent url('../../images/promo-hp.png') no-repeat center center / contain;
                &:after {
                    bottom: -1.75rem;
                }
                &:hover, &.active{
                    background-image: url('../../images/promo-hp-active.png');
                }
            }
            &.dell{
                width: 9rem;
                height: 5.313rem;
                background: transparent url('../../images/promo-dell.png') no-repeat center center / contain;
                &:hover, &.active{
                    background-image: url('../../images/promo-dell-active.png');
                }
            }
            &.ms{
                width: 9rem;
                height: 5.313rem;
                background:transparent url('../../images/promo-ms.png') no-repeat center center / contain;
                &:hover, &.active{
                    background-image: url('../../images/promo-ms-active.png');
                }
            }
        }
    }
    .nav-buttons{
        .icon{
            border-radius: 0.625rem;
            background-color: $light;
            transition: all 0.3s ease-in-out;
            width: 2.5rem;
            height: 2.5rem;
            i{
                line-height: 1;
                color: $dark;
                transition: all 0.3s ease-in-out;
            }
            .count{
                width: 1.188rem;
                height: 1.188rem;
                font-size: 0.625rem;
                font-weight: bold;
                top: -0.25rem;
                right: -0.375rem;
            }
            &.icon-cart, &.icon-toggler, &.icon-account{
                margin-left: 0.875rem;
            }
            &:hover{
                background-color: black;
                i{
                    color: $white;
                }
            }
        }
    }
}
.nav-fix{
    padding-top: 3.5rem;
}
@include media-breakpoint-up(lg) {
    .navbar {
        height: 8.625rem;
        .menu {
            position: static;
            top: auto;
            left: auto;
            width: auto;
            height: auto;
            overflow-y: visible;
            transform: none;
            transition: none;
            background-color: transparent;
            padding: 0;
            &.active {
                transition: none;
            }
            > div{
                padding-left: 0.75rem;
            }
        }
        .contact {
            i{
                font-size: 1.25rem;
                margin-right: 0.75rem;
            }
            a{
                font-size: 0.875rem;
            }
        }
    }
    .nav-fix{
        padding-top: 8.625rem;
    }
}
@include media-breakpoint-up(xl) {
    .navbar {
        .navbar-brand {
            max-width: 15.938rem;
        }
        .contact {
            i{
                font-size: 1.75rem;
                margin-right: 1.25rem;
            }
            a{
                font-size: 1rem;
            }
        }
        .promos{
            .single-promo{
                &:after {
                    content: '';
                    position: absolute;
                    bottom: -1.125rem;
                }
                &.hp{
                    width: 12rem;
                    height: 6.125rem;
                    &:after{
                        bottom: -1.25rem;
                    }
                }
                &.dell{
                    width: 12rem;
                    height: 6.313rem;
                }
                &.ms{
                    width: 12rem;
                    height: 6.313rem;
                }
            }
        }
        .nav-buttons{
            .icon{
                width: 3.625rem;
                height: 3.625rem;
                i{
                    font-size: 1.875rem;
                }
            }
        }
    }
}
