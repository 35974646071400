.single-product-images{
    .glider-track{
        align-items: center;
    }
    img{
        max-width: 25rem;
        max-height: 31.25rem;
        object-fit: contain;
    }
    .image-slider-arrows{
        top: calc(100% + 0.5rem);
        left: 0;
        right: 0;
        z-index: 9;
        .arrow{
            width: 2.125rem;
            height: 2.125rem;
            font-size: 1.5rem;
            &:hover{
                cursor: pointer;
            }
        }
    }
    .image-slider-dots{
        .image-slider-dot, .glider-dot{
            width: 0.375rem;
            height: 0.375rem;
            background-color: $gray-225;
            margin: 0 0.313rem;
            &.active{
                background-color: $gray-625;
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .single-product-images{
        .image-slider-arrows{
            top: 50%;
            transform: translateY(-50%);
            left: calc((100% - 32rem) / 2);
            right: calc((100% - 32rem) / 2);
            .arrow{
                width: 3.125rem;
                height: 3.125rem;
                font-size: 2.25rem;
            }
        }
    }
}
