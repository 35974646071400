.register-form-wrap, .login-form-wrap{
    small{
        font-size: 0.875rem;
    }
    .form-group{
        .field{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            input{
                &:not([type="checkbox"]){
                    width: 100%;
                }
            }
            label{
                font-size: 0.875rem;
            }
            .checkbox{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-wrap: wrap;
                width: 100%;
                cursor: pointer;
                input[type="checkbox"]{
                    &:focus-visible{
                        outline-offset: 0 !important;
                    }
                }
                label{
                    width: calc(100% - 1.75rem);
                    padding-left: 0.625rem;
                    cursor: pointer;
                    text-align: start;
                    margin-top: 0.188rem;
                }
            }
            &.error{
                .sylius-validation-error{
                    margin-top: 0.25rem;
                }
            }
        }
    }
    .forgot-password-btn{
        font-size: 0.875rem;
    }
}
#sylius-api-login-form{
    display: block !important;
}
