.blank-theme{
    .info-box {
        h3{
            color: $white !important;
        }
        &.type-3{
            max-width: 100% !important;
            img{
                max-width: 100%;
                height: auto;
            }
            h3{
                margin-bottom: 3.75rem !important;
            }
            p, ul{
                color: $white !important;
                &:not(:last-child){
                    margin-bottom: 0.5rem !important;
                }
            }
        }
    }
    .content-box{
        h2{
            margin-bottom: 1rem;
            color: $gray-900;
        }
        p{
            color: $black;
        }
    }
}
@include media-breakpoint-up(md) {
    .blank-theme{
        .page-box{
            .content{
                max-width: calc(100% - 21.25rem) !important;
                flex: 0 1 calc(100% - 21.25rem) !important;
            }
            .image{
                max-width: 21.25rem !important;
                flex: 21.25rem !important;
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .blank-theme{
        .page-box{
            &.padding-b{
                .content{
                    padding-bottom: 7.625rem !important;
                }
            }
        }
        .info-box {
            margin-bottom: 0 !important;
            margin-top: -4rem;
            &.mb-minus{
                margin-bottom: -4.5rem !important;
            }
            h3{
                font-size: 2.188rem !important;
                margin-bottom: 1.875rem !important;
            }
            .text{
                &.mb{
                    margin-bottom: 3.5rem !important;
                }
            }
            &.type-3{
                max-width: 90% !important;
            }
        }
        .content-box{
            h2{
                margin-bottom: 3rem;
            }
            p{
                margin-bottom: 1.875rem;
            }
        }
    }
}
