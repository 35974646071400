@import "./actions";
@import "./item";
@import "./variant";
@import "./wishlistChooseModal.scss";
@import "./bulkActions.scss";

.bb-wishlist {
    font-size: 16px;
}

@keyframes beep{
    75%,to {
        transform: scale(2);
        opacity: 0
    }
}

.bb-copy-to-wishlist-sukces {
    animation: beep 1s cubic-bezier(0,0,.2,1) infinite !important;
} 

.bb-copy-to-wishlist-faliure {
    animation: beep 1s cubic-bezier(0,0,.2,1) infinite !important;
} 
