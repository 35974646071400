.filters{
    button[type="submit"]{
        @extend .btn;
        @extend .btn-success;
        @extend .text-white;
        @extend .text-center;
        border-radius: $border-radius-lg;
        padding: 1rem 1.25rem;
        font-size: 0.75rem;
        font-weight: 800;
        word-break: break-word;
    }
    #attributes, #price {
        > .field {
            margin-bottom: 1.5rem;
            flex-wrap: wrap;
            > label {
                font-size: 0.75rem;
                color: $black;
                margin-bottom: 1.25rem;
                letter-spacing: 0.125rem;
                font-weight: 800;
                text-transform: uppercase;
                word-break: break-word;
                display: block;
                width: 100%;
            }
            > a {
                font-size: 0.75rem;
                font-weight: 800;
                letter-spacing: 0.125rem;
                &:hover {
                    cursor: pointer;
                }
                label {
                    cursor: pointer;
                }
                .fa-chevron-up, .fa-chevron-down {
                    display: none;
                }
                &[aria-expanded="false"] {
                    .fa-chevron-down {
                        display: inline-block;
                    }
                }
                &[aria-expanded="true"] {
                    .fa-chevron-up {
                        display: inline-block;
                    }
                }
            }
            .field{
                &:not(:last-child){
                    margin-bottom: 0.75rem;
                }
                .checkbox{
                    min-height: 1.188rem;
                    padding-left: 1.188rem;
                    cursor: pointer;
                    display: flex;
                    align-items: flex-start;
                    input[type="checkbox"]{
                        width: 1.188rem;
                        height: 1.188rem;
                        border-radius: 50%;
                        border: 0.188rem solid $white;
                        outline: 0.125rem solid $gray-400;
                        margin-left: -1.188rem;
                        margin-top: 0;
                        cursor: pointer;
                        vertical-align: top;
                        background-color: #fff;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        appearance: none;
                        &:checked{
                            background-color: $pink;
                            border-color: $white;
                            outline: 0.125rem solid $gray-400;
                            background-image: none !important;
                        }
                        &:focus, &:active{
                            outline: 0.125rem solid $gray-400 !important;
                        }
                    }
                    label{
                        font-size: 0.75rem;
                        letter-spacing: 0.125rem;
                        color: $gray-600;
                        padding-left: 1.5rem;
                        cursor: pointer;
                        font-weight: 800;
                        text-transform: uppercase;
                        width: calc(100% - 2.688rem);
                        word-break: break-word;
                    }
                }
            }
        }
    }
    .single-filter{
        &:not(:last-child){
            margin-bottom: 3.5rem;
        }
        h4{
            font-size: 0.75rem;
            color: $black;
            margin-bottom: 1.25rem;
            letter-spacing: 0.125rem;
        }
        #priceFilter{
            background: $gray-100;
            box-shadow: none;
            height: 0.375rem;
            .noUi-connects{
                border-radius: 0;
                .noUi-connect {
                    background: $pink;
                }
            }
            .noUi-handle{
                width: 0;
                height: 0.75rem;
                right: -0.125rem;
                top: -0.188rem;
                background: $pink;
                box-shadow: none;
                border: 0.063rem solid $pink;
                border-radius: 0;
                cursor: pointer;
                &:before, &:after{
                    display: none;
                }
                &.noUi-handle-lower{
                    right: 0;
                }
                &.noUi-handle-upper{
                    right: -0.125rem;
                }
            }
        }
        .prices{
            font-size: 0.75rem;
            font-weight: 900;
            color: $pink;
            letter-spacing: 0.125rem;
        }
        .single-option{
            &:not(:last-child){
                margin-bottom: 0.75rem;
            }
            .form-check{
                min-height: 1.188rem;
                padding-left: 1.188rem;
                cursor: pointer;
                .form-check-input{
                    width: 1.188rem;
                    height: 1.188rem;
                    border-radius: 50%;
                    border: 0.188rem solid $white;
                    outline: 0.125rem solid $gray-400;
                    margin-left: -1.188rem;
                    margin-top: 0;
                    cursor: pointer;
                    &:checked{
                        background-color: $pink;
                        border-color: $white;
                        outline: 0.125rem solid $gray-400;
                        background-image: none !important;
                    }
                    &:focus, &:active{
                        outline: 0.125rem solid $gray-400 !important;
                    }
                }
                .form-check-label{
                    font-size: 0.75rem;
                    letter-spacing: 0.125rem;
                    color: $gray-600;
                    padding-left: 1.5rem;
                    cursor: pointer;
                }
            }
            .amount{
                font-size: 0.75rem;
                letter-spacing: 0.125rem;
                color: $black;
                padding-left: 0.625rem;
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .filters {
        padding: 1.875rem 3.375rem 1.875rem 3.5rem;
        button[type="submit"]{
            padding: 1.25rem 2.25rem;
        }
    }
}
@include media-breakpoint-up(xxl) {
    .filters {
        padding: 1.875rem 3.375rem 1.875rem 3.5rem;
        button[type="submit"]{
            padding: 1.25rem 2.25rem;
        }
        #attributes, #price {
            > .field {
                > label {
                    display: inline-block;
                    width: auto;
                }
            }
        }
    }
}
