.search-bar{
    .input-group{
        border-radius: $border-radius-lg;
        .search-cat-wrap{
            min-width: 10.625rem;
            border-top-left-radius: 1.813rem;
            border-bottom-left-radius: 1.813rem;
            height: 3.625rem;
            padding: 0 1.75rem 0 2rem;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            width: 100%;
            &:after{
                content: '';
                display: inline-block;
                border-style: solid;
                border-width: 0.375rem 0.281rem 0 0.281rem;
                border-color: $dark transparent transparent transparent;
                width: 0;
                height: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 2rem;
            }
            .form-control{
                height: 100%;
                border: 0;
                padding: 0;
                font-size: 0.75rem;
                font-weight: 700;
                color: $black;
                cursor: pointer;
            }
        }
        .search-button{
            width: 2.813rem;
            height: 2.813rem;
            font-size: 1.75rem;
        }
    }
}
.results{
    box-shadow: 0 1rem 0.625rem rgba(0,0,0,0.1);
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    background-color: $white;
    left: auto;
    right: 0;
    transform: none;
    min-width: 100%;
    .result__link{
        display: block;
        padding: 0;
        .result__container{
            box-shadow: none;
            border-bottom: 1px solid #d4d4d5;
            padding-bottom: 0.5rem;
            transition: all 0.3s ease-in-out;
            .result__image{
                max-width: 5rem;
                max-height: 5rem;
                width: 5rem;
                height: 5rem;
            }
            .result__content{
                padding: 0.5rem;
                font-size: 1rem;
                width:100%;
                .js-title{
                    width: 70%;
                    font-weight: 400;
                }
                .result__price{
                    width: 20%;
                    color: $orange;
                    display: flex;
                    position: relative;
                    justify-content: end;
                }
            }
        }
    }
    .result__category{
        font-size: 1.125rem;
        font-weight: 800;
    }
}
@include media-breakpoint-up(md) {
    .search-bar {
        .input-group {
            .search-cat-wrap {
                border-right: 0.063rem solid $gray-100;
                width: auto;
            }
        }
    }
}
