.content-box{
    border-radius: $border-radius-lg;
    padding: 1.875rem 1.25rem;
}
@include media-breakpoint-up(xl) {
    .content-box{
        padding: 3rem 3.75rem 3rem 4rem;
        &.padding-t{
            padding-top: 7.75rem;
        }
    }
}
