.page-box{
    border-radius: $border-radius-lg;
    .content{
        border-bottom-left-radius: 1.813rem;
        border-bottom-right-radius: 1.813rem;
        max-width: 100%;
        flex: 0 1 100%;
        z-index: 1;
        padding: 1.875rem 1.25rem;
        h2{
            color: $gray-900;
            font-weight: 700;
            font-size: 1.5rem;
            margin-bottom: 1.25rem;
        }
        p{
            font-size: 0.875rem;
            color: $black;
            font-weight: 300;
        }
        img{
            margin: 2.25rem 0;
        }
        .contact-box{
            border-top: 0.063rem solid $gray-475;
            margin-top: 1.875rem;
            padding-top: 1.25rem;
            > div{
                margin-bottom: 1.5rem;
            }
            .icon{
                width: 1.5rem;
                margin-right: 1rem;
                i{
                    font-size: 1.25rem;
                }
            }
            p{
                font-size: 1rem;
                font-weight: 300;
                color: $black;
                line-height: 1.56;
                width: calc(100% - 2.75rem);
                a{
                    color: $primary;
                    font-size: 1rem;
                    line-height: 1;
                    font-weight: 700;
                    small{
                        font-size: 0.875rem;
                        margin-bottom: 0.063rem;
                    }
                }
            }
        }
        .review-box{
            border-top: 0.063rem solid $gray-475;
            margin-top: 1.875rem;
            padding-top: 1.25rem;
            .note{
                font-size: 1.5rem;
                line-height: 1;
                margin-bottom: 1.25rem;
            }
            .rating{
                i{
                    font-size: 1.5rem;
                    margin: 0 0.625rem;
                    color: $gray-475;
                    line-height: 1;
                    &:first-child{
                        margin-left: 0;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            p{
                font-size: 1rem;
                font-weight: 300;
                color: $black;
            }
        }
    }
    .image{
        max-width: 100%;
        flex: 0 1 100%;
        border-top-left-radius: 1.813rem;
        border-top-right-radius: 1.813rem;
        background-position: right top;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
        overflow: hidden;
        height: 20rem;
    }
}
@include media-breakpoint-up(md) {
    .page-box {
        .content{
            padding: 2.25rem 4rem 2.25rem 2.5rem;
            max-width: calc(100% - 14.063rem);
            flex: 0 1 calc(100% - 14.063rem);
            border-top-left-radius: 1.813rem;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 1.813rem;
        }
        .image{
            max-width: 14.063rem;
            flex: 14.063rem;
            border-top-left-radius: 0;
            border-bottom-right-radius: 1.813rem;
            height: auto;
        }
    }
}
@include media-breakpoint-up(xl) {
    .page-box {
        .content {
            padding: 3rem 5rem;
            h2{
                font-size: 2.188rem;
                line-height: 1.42;
                margin-bottom: 2.25rem;
            }
            p{
                font-size: 1rem;
                line-height: 1.56;
            }
            img{
                margin: 3.75rem 0;
            }
            .contact-box{
                margin-top: 3.75rem;
                padding-top: 3rem;
                > div{
                    &:not(:last-child){
                        margin-bottom: 1.875rem;
                    }
                }
                .icon{
                    width: 2.188rem;
                    margin-right: 1.875rem;
                    i{
                        font-size: 2.188rem;
                    }
                }
                p{
                    width: calc(100% - 4.063rem);
                    margin-bottom: 1.125rem;
                    a{
                        font-size: 2.188rem;
                        small{
                            font-size: 1rem;
                            margin-bottom: 0.125rem;
                        }
                        &.smaller{
                            font-size: 1rem;
                        }
                    }
                }
            }
            .review-box{
                margin-top: 5.5rem;
                padding-top: 4rem;
                .note{
                    font-size: 2.188rem;
                    margin-bottom: 1.875rem;
                }
                .rating{
                    i{
                        font-size: 2.625rem;
                        margin: 0 0.938rem;
                    }
                }
            }
        }
        &.padding-b{
            .content{
                padding-bottom: 12.125rem !important;
            }
        }
    }
}
