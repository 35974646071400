.product-page-content {
    border-radius: $border-radius-lg;
    padding: 1.125rem;
    .breadcrumb{
        div, a{
            font-size: 0.75rem;
            color: $black;
            &.divider{
                margin: 0 0.5rem;
                color: $gray-400;
            }
        }
    }
    h1{
        font-size: 1.125rem;
        line-height: 1.2;
        font-weight: 700;
    }
    .code{
        font-size: 0.75rem;
    }
    .nav-tabs{
        border-bottom: 0.063rem solid $gray-300;
        margin-top: 2.5rem;
        .nav-item{
            width: 50%;
            .nav-link{
                font-size: 0.75rem;
                font-weight: 700;
                padding: 0.5rem 0.125rem;
                color: $black;
                line-height: 1;
                &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                    width: 100%;
                    opacity: 0;
                    visibility: hidden;
                    background-color: transparent;
                    height: 0.25rem;
                    transition: all 0.3s ease-in-out;
                }
                &.active, &:hover{
                    color: $primary;
                    &:after{
                        opacity: 1;
                        visibility: visible;
                        background-color: $primary;
                    }
                }
            }
        }
    }
    table{
        tr{
            td{
                font-size: 0.75rem;
                vertical-align: top;
                line-height: 1;
                padding: 1rem 0.5rem 1rem 0;
                width: 50%;
                border-bottom-color: $gray-300;
            }
            &:last-child{
                td{
                    border-bottom: 0;
                }
            }
        }

    }
    .price-wrap{
        padding-bottom: 0.375rem;
        border-bottom: 0.063rem solid $gray-400;
        .price{
            font-size: 0.75rem;
            color: $gray-500;
            line-height: 1;
            margin-bottom: 0.75rem;
            span{
                color: $black;
                font-weight: 700;
                &.bg-primary{
                    border-radius: 0.938rem;
                    font-size: 1rem;
                }
            }
        }
    }
    .additional-info{
        margin-top: 1.875rem;
        p{
            font-size: 0.75rem;
            margin-bottom: 0.625rem;
        }
    }
    .links{
        margin-top: 2.625rem;
        a{
            padding: 1.125rem 0;
            font-size: 0.75rem;
            line-height: 1.2;
            color: $black;
            i{
                font-size: 1.25rem;
            }
            &:not(:last-child){
                border-bottom: 0.063rem solid $gray-400;
            }
        }
    }
    .quantity{
        margin-top: 3.125rem;
        margin-bottom: 1.875rem;
        .minus, .plus {
            width: 2.375rem;
            height: 2.375rem;
            font-size: 1rem;
            font-weight: bold;
        }
        input{
            border-bottom: 0.063rem solid $gray-400;
            border-left: 0;
            border-right: 0;
            border-top: 0;
            color: $black;
            width: 3rem;
            margin: 0 1.25rem;
            padding-bottom: 1rem;
            line-height: 1;
            font-size: 1rem;
            -moz-appearance: textfield;
            text-align: center;
            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button{
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
    .add-to-cart{
        .btn{
            border-radius: $border-radius-lg;
            padding: 1rem 1.25rem;
            font-size: 0.75rem;
        }
    }
    .loading{
        position: relative;
        &:after{
            content: '';
            position: absolute;
            background-color: #000000;
            opacity: .7;
            width:100%;
            height: 100%;
            top:0;
            left:0;
        }
        .loading-anim-helper{
            &:after{
                position: absolute;
                display: block;
                content: '';
                top: calc(50% - 0.5rem);
                right: calc(50% - 0.5rem);
                width: 1rem;
                height: 1rem;
                -webkit-animation: button-spin 0.6s linear;
                animation: button-spin 0.6s linear;
                -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
                border-radius: 2rem;
                border-color: #FFFFFF transparent transparent;
                border-style: solid;
                border-width: 0.2em;
                -webkit-box-shadow: 0px 0px 0px 1px transparent;
                box-shadow: 0px 0px 0px 1px transparent;
                z-index: 10;
            }
            &:before{
                position: absolute;
                top:50%;
                right:auto;
                left: 50%;
                white-space: nowrap;
                color: $white;
                transform: translate(-50%, -100%);
                content: 'Proszę czekać';
                z-index: 10;
            }
        }
        .btn {
            @-webkit-keyframes button-spin {
                from {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                }

                to {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                }
            }
            @keyframes button-spin {
                from {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                }

                to {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                }
            }

        }
    }
}
@include media-breakpoint-up(md) {
    .product-page-content {
        h1 {
            font-size: 1.313rem;
        }
        .nav-tabs {
            .nav-item {
                width: auto;
                padding-right: 0.75rem;
            }
        }
        .add-to-cart {
            .btn {
                padding: 1.25rem 2.25rem;
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .product-page-content {
        padding: 1.25rem 1.875rem;
        .nav-tabs{
            .nav-item{
                padding-right: 5rem;
            }
        }
    }
}
