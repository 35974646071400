.pagination{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    .pagination-arrow{
        font-size: 1rem;
        color: $gray-500;
        &.pagination-prev{
            margin-right: 0.813rem;
        }
        &.pagination-next{
            margin-left: 0.813rem;
        }
    }
    .page, .item{
        color: $gray-500;
        margin: 0 0.813rem;
        font-size: 0.75rem;
        font-weight: 500;
        letter-spacing: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active{
            border-radius: 0.625rem;
            background-color: $white;
            font-weight: 900;
            width: 3.625rem;
            height: 3.625rem;
        }
        &.prev{
            font-size: 0;
            color: $gray-500;
            margin-left: 0;
            margin-right: 0.813rem;
            flex-direction: column;
            &:before{
                content: '\f104';
                font-family: 'Font Awesome 5 Free';
                font-size: 1rem;
                font-weight: bold;
                display: block;
            }
        }
        &.next{
            font-size: 0;
            color: $gray-500;
            margin-left: 0.813rem;
            margin-right: 0;
            flex-direction: column;
            &:before{
                content: '\f105';
                font-family: 'Font Awesome 5 Free';
                font-size: 1rem;
                font-weight: bold;
                display: block;
            }
        }
    }
}
