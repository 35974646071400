.main-footer{
    padding: 2.5rem 0;
    .logo{
        margin-bottom: 1.5rem;
        max-width: 13.75rem;
    }
    p{
        font-size: 0.75rem;
        margin-bottom: 1.25rem;
        font-weight: 500;
        letter-spacing: 0.125rem;
    }
    strong{
        font-weight: 800;
    }
    a{
        color: $dark;
        &:hover{
            color: $primary;
        }
    }
    h3{
        font-size: 0.75rem;
        font-weight: 800;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }
    ul{
        li{
            a{
                font-size: 0.75rem;
                color: $gray-600;
                padding: 0.375rem 0;
                letter-spacing: 0.125rem;
            }
        }
    }
    .socials{
        padding-top: 0.625rem;
        .icon{
            width: 3.625rem;
            height: 3.625rem;
            border-radius: 0.625rem;
            background-color: $gray-100;
            font-size: 1.875rem;
            color: $dark;
            &:not(:last-child){
                margin-right: 1.25rem;
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .main-footer {
        .logo{
            max-width: 100%;
        }
        p {
            a {
                display: block;
            }
        }
        ul{
            li{
                a{
                    padding: 0.625rem 0;
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .main-footer{
        .logo{
            margin-bottom: 5rem;
        }
        h3{
            margin-top: 2.5rem;
            margin-bottom: 0.875rem;
        }
    }
}
@include media-breakpoint-up(xxl) {
    .main-footer {
        p {
            a{
                display: inline-block;
            }
        }
    }
}
