* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
a, .btn, button{
    transition: all 0.3s ease-in-out;

}
input, textarea, .btn, button, select{
    &:focus{
        box-shadow: none !important;
        outline: none !important;
    }
}
.row{
        color: $gray-600;
        font-size: 1.25rem;
}
strong{
    font-weight: 800;
}
a{
    text-decoration: none;
    &:hover, &:active, &:focus{
        text-decoration: none;
    }
}
.glider{
    overflow: hidden !important;
}
.quantity{
    label{
        display: none !important;
    }
}
.btn-outline-primary{
    &:hover {
        color: $white;
    }
}
.sylius-flash-message{
    padding: 1.875rem;
    background-color: $white;
    border-radius: $border-radius-lg;
    .content{
        *{
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}
.sylius-validation-error{
    color: $red;
    font-size: 0.75rem;
    line-height: 1;
    margin-top: 0.25rem;
    width: 100%;
}
.custom-item {
    &.active{
        background-color: #22242626;
    }
    &.disabled{
        pointer-events: none;
    }
    padding: 0.5rem 3rem;
    font-size: 1.125rem;
    color: $black;
    border-bottom: 1px solid #22242626;
    &:last-child {
        border: none;
        text-decoration: none;
        font-weight: 600;
        color: $red;
        &:hover{
         color: #ed1126;
        }
    }
    &:first-child {
        font-weight: 800;
    }
}

.btn {
    &-outline-primary, &-outline-secondary{
        color: $black;
        font-size: 0.75rem;
        font-weight: 800;
        padding: 1.25rem 2.25rem;
        &:hover{
            color: $white;
        }
    }
    &-primary, &-secondary {
        border-radius: 28px;
        color: $white;
        font-size: 0.5rem;
        font-weight: 800;
        padding: 0.75rem 1rem;
        @include media-breakpoint-up(xl){
            padding: 1.25rem 2.25rem;
            font-size: 0.75rem;
        }
        &:hover {
            color: $white;
            font-weight: 800;
        }
    }
}
input[type=checkbox], input[type=radio]{
    margin: 4px;
    padding: 0;
    min-width: 19px;
    height: 19px;
    border: 0.188rem solid #fff;
    outline: 0.125rem solid #d1d1d1;
    cursor: pointer;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    box-shadow: none;
    appearance: none;
    &:checked{
        background-color: #ef5453;
    }
    &:focus{
        outline: 0.125rem solid #d1d1d1 !important;
    }
}
input[type=radio]{
    border-radius: 50%;
}
textarea{
    border: 0.063rem solid #d9d9d9;
    color: #a9a9a9;
    padding: 0 0.938rem;
    font-size: 0.75rem;
}
input{
    &[type=text],
    &[type=textarea],
    &[type=email],
    &[type=password],
    &[type=url],
    &[type=tel],
    &[type=date],
    &[type=number],
    &[type=time],
    &[type=week]{
        border: 0.063rem solid #d9d9d9;
        color: #a9a9a9;
        padding: 0 0.938rem;
        font-size: 0.75rem;
        height: 2.813rem;
    }
}
.province-container{
    label{
        @extend .col-12;
    }
}
.profile-form-wrap {
    input, select{
        margin-bottom: 0.5rem;
    }
    label, input:not([type=checkbox]), select, text {
        width: 100%;
        display:block;
    }
    .checkbox {
        display: flex;
    }
}
.modal{
    &-content{
        .modal-body{
            label {
                width: 100%;
                @extend .fs-5;
            }
            input,textarea {
                width: 100%;
            }
        }
    }
}
.error{
    color: $red;
}

#price .field, #price .field .labeled{
    display: flex;
    align-items: center;
}
#price .field{
    &:not(.labeled){
        flex-wrap: wrap;
    }
}
#price .field label{
    margin-bottom: 0.5rem !important;
}
#price .field .labeled .label{
    order: 2;
    width: 100%;
    display: block;
}
#price .field .labeled input{
    width: 150px;
    margin-right: 8px;
}

@include media-breakpoint-up(md) {
    .custom-width{
        width: 40rem;
    }
}
@include media-breakpoint-up(xxl) {
    #price .field label{
        margin-bottom: 0 !important;
    }
    #price .field .labeled .label{
        width: auto;
    }
    #price .field .labeled input{
        margin-left: 8px;
    }
}
