$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$fa-font-path: "~font-awesome/fonts";

$white:    #fff !default;
$gray-100: #f1f1f1 !default; // Project
$gray-200: #e5e5e5 !default; // Project
$gray-225: #e4e4e4 !default; // Project
$gray-250: #e3e3e3 !default; // Project
$gray-300: #ebebeb !default; // Project
$gray-400: #d1d1d1 !default; // Project
$gray-425: #d9d9d9 !default; // Project
$gray-450: #c1c1c1 !default; // Project
$gray-475: #b9b9b9 !default; // Project
$gray-500: #a9a9a9 !default; // Project
$gray-600: #66686a !default; // Project
$gray-625: #626262 !default; // Project
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #2c2c2c !default; // Project
$black:    #000 !default;

$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #ef5453 !default; // Project
$red:     #dc3545 !default;
$orange:  #f15a29 !default;
$yellow:  #fcd500 !default; // Project
$green:   #89ca18 !default; // Project
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;

$primary:       $orange !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $black !default;

$font-family-sans-serif:      'Montserrat', sans-serif;
$font-family-base:            $font-family-sans-serif !default;

$headings-font-family:        $font-family-base !default;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1920px
) !default;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1430px,
    xxxl: 1614px
) !default;

$border-radius:               0 !default;
$border-radius-sm:            15px !default;
$border-radius-lg:            28px !default;

$enable-smooth-scroll: false;
