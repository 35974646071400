.lp{
    .navbar{
        height: 8.625rem !important;
        .navbar-brand{
            max-width: 16.25rem;
        }
        .contact{
            color: $dark;
            &:hover{
                color: $primary;
            }
            strong{
                font-weight: 700;
            }
        }
    }
    .nav-fix{
        padding-top: 8.625rem !important;
    }
    .basecamp{
        min-height: calc(100vh - 15.438rem);
    }
    .main-content-wrapper{
        padding: 0 !important;
    }
    .countdown{
        p{
            font-size: 0.75rem;
            font-weight: 600;
        }
        span{
            font-size: 1.5rem;
            font-weight: 600;
            width: 3rem;
            height: 3rem;
            border-radius: 0.625rem;
        }
    }
    .main-footer{
        .footer-wrap{
            border-top: 0.063rem solid $gray-425;
        }
        .logo{
            max-width: 10rem;
        }
        h3{
            letter-spacing: 2px;
            font-weight: 700;
        }
        .socials{
            .icon{
                font-size: 1.125rem;
                width: 1.875rem;
                height: 1.875rem;
            }
        }
    }
    &.counting{
        background: $light url('../../images/lp/landing-bg.jpg') no-repeat center top / cover;
        &:before{
            content: '';
            display: block;
            background-color: rgba(0, 0, 0, 0.3);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
        .main-content-wrapper{
            background-color: transparent !important;
        }
        .basecamp{
            min-height: 100vh !important;
            z-index: 2;
        }
    }
    &.loaded{
        .ui.container{
            max-width: 100% !important;
            padding: 0 !important;
        }
        .banner{
            h1{
                font-size: 53px;
                font-weight: bold;
            }
            p{
                font-size: 20px;
                font-weight: bold;
                &.small{
                    font-size: 12px;
                }
            }
            .btn{
                font-size: 20px;
                font-weight: bold;
            }
            .container{
                > div{
                    >  *{
                        margin-bottom: 40px;
                    }
                }
            }
        }
        .lp-subpages{
            padding: 32px 0;
            .single-page{
                border-radius: 28px;
                height: 290px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                &:before{
                    content: '';
                    background-color: #fff;
                    display: block;
                    width: 60%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    z-index: 1;
                    border-bottom-left-radius: 28px;
                    border-top-left-radius: 28px;
                }
                h3{
                    font-size: 25px;
                    line-height: 32px;
                    color: #2c2c2c;
                    font-weight: 700;
                    margin-top: 30px;
                    margin-bottom: 20px;
                }
                img{
                    max-width: 180px;
                }
                .btn{
                    margin-bottom: 30px;
                    padding: 1.25rem 2.25rem !important;
                    font-size: 0.75rem !important;
                    width: 100%;
                }
                .content{
                    padding: 0 30px 0;
                    z-index: 2;
                }
            }
        }
        .lp-products{
            padding: 30px 0;
            .single{
                >.row{
                    margin-bottom: 40px;
                }
                h3{
                    font-size: 24px;
                    line-height: 32px;
                    color: $gray-900;
                    font-weight: 700;
                }
                .description{
                    margin-bottom: 40px;
                    font-size: 16px;
                    line-height: 25px;
                }
                .show-btn{
                    font-weight: 700;
                    border-bottom: 2px solid $primary;
                    font-size: 16px;
                    &:not(.collapsed){
                        i{
                            &:before{
                                content: "\f0d8" !important;
                            }
                        }
                    }
                }
                .btn{
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1;
                    color: $primary;
                    border-radius: 42px;
                    span{
                        font-size: 24px;
                    }
                    &:hover{
                        color: $white;
                    }
                }
                .table{
                    tr{
                        td{
                            padding: 14px 20px;
                            font-size: 16px;
                            border-color: $gray-200 !important;
                        }
                        &:first-child{
                            td{
                                padding-top: 30px;
                            }
                        }
                    }

                }
            }
        }
        .lp-contact-form{
            padding: 40px 0;
            h2{
                margin-bottom: 40px;
                font-size: 24px;
                color: $gray-900;
                font-weight: 700;
            }
            .field{
                margin-bottom: 16px;
            }
            .dropdown{
                appearance: auto !important;
            }
            .form-control{
                background-color: #fcfcfc;
                border: 1px solid #dcdcdc;
                font-size: 16px;
                color: $dark;
                width: 100%;
                &::placeholder{
                    color: $dark;
                }
                &:not(textarea){
                    height: 49px;
                }
            }
            h4{
                font-size: 18px;
                font-weight: 700;
            }
            .checkbox{
                display: flex;
                align-items: center;
                margin: 0;
                padding-left: 0;
                input{
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    margin-left: 0;
                    margin-right: 10px;
                    &:focus-visible {
                        outline-offset: 0 !important;
                    }
                }
                label{
                    line-height: 1;
                    font-size: 16px;
                    color: $dark;
                    &:hover{
                        cursor: pointer;
                    }
                }
                &:hover{
                    cursor: pointer;
                }
            }
            .btn{
                font-size: 20px;
                border-radius: 29px;
                line-height: 1;
                font-weight: 700;
                &.btn-outline-primary{
                    color: $primary;
                    &:hover{
                        color: $white;
                    }
                }
            }
        }
        &.lenovo {
            .banner {
                .container{
                    > div{
                        >  * {
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
        &.microsoft{
            .lp-microsoft{
                padding: 40px 0 30px;
                h2{
                    font-size: 24px;
                    line-height: 32px;
                    color: $gray-900;
                    font-weight: 700;
                }
                .btn{
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1;
                    color: $primary;
                    border-radius: 42px;
                    span{
                        font-size: 24px;
                    }
                    &:hover{
                        color: $white;
                    }
                }
                .listing{
                    margin: 0 -10px;
                    > div{
                        max-width: 50%;
                        flex: 0 1 50%;
                        padding: 0 10px;
                        .img-wrap{
                            height: 66px;
                            margin-bottom: 36px;
                        }
                        p{
                            font-size: 16px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
        &.connexion{
            .lp-section{
                h2{
                    font-size: 24px;
                    line-height: 32px;
                    color: $gray-900;
                    font-weight: 700;
                }
                p{
                    font-size: 16px;
                }
            }
            .lp-products{
                padding: 40px 0 30px;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .lp {
        .countdown {
            p {
                font-size: 0.875rem;
            }

            span {
                font-size: 3rem;
                width: 5rem;
                height: 5rem;
            }
        }
        .basecamp{
            min-height: calc(100vh - 20.188rem);
        }
        .main-footer {
            .logo {
                max-width: 18.75rem;
            }

            .socials {
                .icon {
                    font-size: 1.875rem;
                    width: 3.625rem;
                    height: 3.625rem;
                }
            }
        }
        &.loaded{
            .banner{
                min-height: 828px;
                .container{
                    min-height: 828px;
                    padding-bottom: 100px;
                    > div{
                        >  * {
                            margin-bottom: 100px;
                        }
                    }
                }
                .logos{
                    position: absolute;
                    bottom: 30px;
                    left: 12px;
                    right: 12px;
                }
            }
            .lp-subpages{
                padding: 60px 0 90px;
                .single-page{
                    height: 460px;
                    h3{
                        font-size: 35px;
                        line-height: 50px;
                        margin-top: 40px;
                        margin-bottom: 30px;
                    }
                    img{
                        max-width: 280px;
                    }
                    .btn{
                        margin-bottom: 70px;
                        width: auto;
                    }
                    .content{
                        padding: 0 20px 0 40px;
                    }
                }
            }
            .lp-products{
                .single{
                    h3{
                        font-size: 35px;
                        line-height: 50px;
                    }
                    .btn{
                        span{
                            font-size: 35px;
                        }
                    }
                    .table{
                        tr{
                            td{
                                padding: 14px 30px;
                            }
                        }
                    }
                }
            }
            .lp-contact-form {
                padding: 100px 0;
                h2 {
                    margin-bottom: 140px;
                    font-size: 53px;
                }
            }
            &.lenovo {
                .banner {
                    min-height: 565px;
                    .container{
                        min-height: 565px;
                        > div{
                            >  * {
                                margin-bottom: 60px;
                            }
                        }
                    }
                }
            }
            &.microsoft{
                .banner {
                    min-height: 550px;
                    .container{
                        min-height: 550px;
                        > div{
                            >  * {
                                margin-bottom: 60px;
                            }
                        }
                    }
                }
                .lp-microsoft{
                    padding: 80px 0 70px;
                    h2{
                        font-size: 35px;
                        line-height: 50px;
                    }
                    .btn{
                        span{
                            font-size: 35px;
                        }
                    }
                    .listing{
                        margin: 0 -25px;
                        > div{
                            max-width: 20%;
                            flex: 0 1 20%;
                            padding: 0 25px;
                        }
                    }
                }
            }
            &.connexion {
                .banner {
                    min-height: 535px;
                    .container {
                        min-height: 535px;

                        > div {
                            > * {
                                margin-bottom: 60px;
                            }
                        }
                    }
                }
                .lp-section{
                    h2{
                        font-size: 35px;
                        line-height: 50px;
                    }
                }
                .lp-products{
                    padding: 80px 0 70px;
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .lp{
        &.loaded{
            .banner{
                background: $white url('../../images/lp/home/banner.png') no-repeat right center;
                .container{
                    background: linear-gradient(45deg, rgba(255, 255, 255, 0.5) 0%, transparent 100%);
                }
            }
            &.lenovo{
                .banner {
                    background: $white url('../../images/lp/lenovo/lenovo-banner.png') no-repeat right center;
                }
            }
            &.microsoft{
                .banner{
                    background: $white url('../../images/lp/microsoft/microsoft-banner.png') no-repeat 97% center;
                }
                .lp-microsoft{
                    .listing{
                        margin: 0 -35px;
                        > div{
                            max-width: 10%;
                            flex: 0 1 10%;
                            padding: 0 35px;
                        }
                    }
                }
            }
            &.connexion{
                .banner{
                    background: $white url('../../images/lp/3dconnexion/3dconnexion-banner.png') no-repeat right center;
                }
                .lp-section{
                    img{
                        margin-top: -10px;
                        margin-bottom: -24px !important;
                        margin-left: -108px;
                    }
                }
            }
        }
    }
}
