#homepageSlider{
    height: calc(100% - 4.75rem) !important;
    .glider-track{
        height: 100%;
    }
}
.homepage-box{
    height: 600px !important;
    border-radius: $border-radius-lg;
    .content{
        z-index: 3;
        h3{
            color: $gray-900;
            font-weight: 700;
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
        }
        ul{
            padding-left: 1.5rem;
        }
        p, ul{
            font-size: 0.875rem;
            margin-bottom: 1.25rem;
            color: $dark;
        }
        .price{
            font-size: 1.5rem;
            font-weight: 700;
        }
        .btn{
            margin-top: 1.25rem;
            border-radius: $border-radius-lg;
            font-size: 0.75rem;
            font-weight: 800;
            padding: 1.25rem 3.125rem;
            line-height: 1.2;
        }
    }
    .stroke-content{
        z-index: 2;
        h3{
            -webkit-text-stroke: 0.063rem $white;
            color: rgba(255, 255, 255,0);
            font-size: 2.5rem;
            letter-spacing: 0.625rem;
            font-weight: 900;
        }
        .price{
            -webkit-text-stroke: 0.063rem $white;
            color: rgba(255, 255, 255,0);
            font-size: 3rem;
            letter-spacing: 0.625rem;
            font-weight: 900;
        }
    }
    .content-side{
        border-bottom-left-radius: 1.813rem;
        border-bottom-right-radius: 1.813rem;
        max-width: 100%;
        flex: 0 1 100%;
        z-index: 1;
    }
    .image-side{
        max-width: 100%;
        flex: 0 1 100%;
        border-top-left-radius: 1.813rem;
        border-top-right-radius: 1.813rem;
        background-position: right top;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
        overflow: hidden;
    }
}
@include media-breakpoint-up(md) {
    .homepage-box {
        height: auto;
        .content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 2.5rem;
            max-width: 50%;
        }
        .stroke-content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 2.5rem;
            max-width: 90%;
        }
        .content-side {
            max-width: 17.625rem;
            flex: 0 1 17.625rem;
            border-top-left-radius: 1.813rem;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 1.813rem;
        }
        .image-side {
            max-width: calc(100% - 17.625rem);
            flex: 0 1 calc(100% - 17.625rem);
            border-top-left-radius: 0;
            border-bottom-right-radius: 1.813rem;
        }
    }
}
@include media-breakpoint-up(lg) {
    .homepage-box{
        .content{
            left: 3.75rem;
            max-width: 35%;
        }
        .stroke-content{
            left: 3.75rem;
        }
        .content-side{
            max-width: 20.625rem;
            flex: 0 1 20.625rem;
        }
        .image-side{
            max-width: calc(100% - 20.625rem);
            flex: 0 1 calc(100% - 20.625rem);
        }
    }
}
@include media-breakpoint-up(xl) {
    .homepage-box {
        height: 100% !important;
        .content {
            max-width: 50%;
            h3 {
                font-size: 2.188rem;
                margin-bottom: 3.375rem;
            }
            p, ul{
                font-size: 1rem;
                margin-bottom: 3.375rem;
            }
            ul{
                li{
                    &:not(:last-child){
                        margin-bottom: 1rem;
                    }
                }
            }
            .price {
                font-size: 2.188rem;

            }
            .btn{
                margin-top: 3.75rem;
            }
        }
        .stroke-content {
            h3 {
                font-size: 4rem;
            }
            .price {
                font-size: 8.625rem;
            }
        }
    }
}
#dots{
    left: 0;
    right: 0;
    bottom: 90px;
    .glider-dot{
        background-color: $secondary;
        &.active{
            background-color: $primary;
        }
    }
}
