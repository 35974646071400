.cart-wrapper{
    .btn{
        font-size: 0.75rem;
        font-weight: 800;
        padding: 1.25rem 0.875rem;
        line-height: 1.2;
        margin-bottom: 1.25rem;
        width: 11.875rem;
    }
    .cart-items-wrap{
        padding: 1.875rem 1.125rem;
        #sylius-cart-items{
            display: block;
            tbody{
                display: block;
            }
            tr{
                display: flex;
                flex-direction: column;
                border-bottom: 0.063rem solid $gray-300;
            }
            .image-wrap{
                width: 4.5rem;
                height: 4.5rem;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }
            td{
                font-size: 0.75rem;
                line-height: 1.2;
                padding: 0.5rem;
                display: flex;
                width: 100%;
                justify-content: center;
                border: 0;
                &:first-child{
                    order: 1;
                }
                &:nth-child(2){
                    order: 2;
                }
                &:nth-child(3){
                    order: 3;
                }
                &:last-child{
                    order: 0;
                    justify-content: flex-end;
                }
                &.prices{
                    flex-direction: column;
                }
            }
            .quantity{
                font-size: 0.875rem;
                line-height: 1;
                .plus, .minus{
                    width: 1.875rem;
                    height: 1.875rem;
                    font-size: 1rem;
                }
                input{
                    border: 0;
                    width: 2rem;
                    font-size: 1rem;
                    text-align: center;
                    display: block;
                    -moz-appearance: textfield;
                    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button{
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }
            }
            .content{
                margin-left: 1.25rem;
                width: calc(100% - 3.75rem);
            }
        }
    }
    .cart-summary-wrap{
        padding: 1.5rem 1.875rem 1.875rem;
        .totals{
            padding: 0 0.25rem;
            margin-bottom: 1.875rem;
            border-bottom: 0.063rem solid $gray-400;
            .table{
                tr{
                    border: 0;
                }
                td{
                    color: $gray-500;
                    font-size: 12px;
                    line-height: 1.2;
                    font-weight: 300;
                    padding: 0.375rem 0;
                    border: 0;
                    vertical-align: middle;
                    border-bottom: 0.063rem solid $gray-300;
                    &:last-child{
                        font-weight: 700;
                        color: $dark;
                        padding: 0.375rem 0.625rem;
                    }
                    .items-subtotal{
                        padding: 0.313rem 0.625rem 0.313rem 0.875rem;
                        font-size: 1rem;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}
#after-add-to-cart-modal{
    .modal-header{
        border-top-left-radius: 1.75rem;
        border-top-right-radius: 1.75rem;
    }
    .modal-footer{
        border-bottom-right-radius: 1.75rem;
        border-bottom-left-radius: 1.75rem;
    }
    .modal-header, .modal-body, .modal-footer{
        padding: 1.125rem;
    }
}
@include media-breakpoint-up(md) {
    #after-add-to-cart-modal{
        .modal-dialog{
            max-width: 46.25rem;
        }
        .modal-header, .modal-body, .modal-footer{
            padding: 1.25rem 1.875rem;
        }
    }
    .cart-wrapper {
        .cart-items-wrap {
            #sylius-cart-items {
                display: table;
                tbody {
                    display: table-row-group;
                }

                tr {
                    display: table-row;
                }
                td {
                    display: table-cell;
                    &.info{
                        width: 40%;
                    }
                    &.prices{
                        width: 30%;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(xxl) {
    .cart-wrapper {
        .cart-items-wrap {
            #sylius-cart-items {
                td {
                    padding: 1rem;
                }
                .quantity{
                    font-size: 1rem;
                    .plus, .minus{
                        width: 2.5rem;
                        height: 2.5rem;
                        font-size: 1.25rem;
                    }
                }
            }
        }
    }
}
