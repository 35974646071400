.bitbag.add-wishlist-modal-initialized{
    z-index:9999;
    .add-wishlist-confirmation-modal{
        &__confirm {
            &--perform {
                @extend .btn;
                @extend .btn-primary;
                @extend .rounded-3;
            }
            &--cancel {
                @extend .btn;
                @extend .btn-secondary;
                @extend .rounded-3;
            }
        }
    }
}
.bitbag.copy-modal-initialized{
    z-index:9999;
    .copy-confirmation-modal{
        &__confirm {
            &--perform {
                @extend .btn;
                @extend .btn-primary;
                @extend .rounded-3;
            }
            &--cancel {
                @extend .btn;
                @extend .btn-secondary;
                @extend .rounded-3;
            }
        }
    }
}
.ui.container-lg {
    input[type="number"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
        border: none;
        text-align: center;
        min-width: 30px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }
}
#wishlist{
    .wishlist{
        &-name{
            font-size: 1rem;
            @include media-breakpoint-up(xl){
                font-size: 1.25rem;

            }
        }
        &-price{
            font-size: 0.75rem;
            @include media-breakpoint-up(xl){
                font-size: 1rem;
            }
        }
    }
    .image-wrap{
        width: 100%;
        height: 100%;
    }
}
