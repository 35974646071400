.contact-box{
    h2{
        font-size: 1.5rem;
        color: $gray-900;
        margin-bottom: 1.25rem;
    }
    .form-group{
        margin-bottom: 1.25rem;
    }
    .form-control, .form-select, .file-input{
        border-radius: $border-radius-lg;
        font-size: 0.75rem;
        line-height: 1;
        color: $gray-500;
        font-weight: 300;
        padding: 1.875rem 1.5rem 1.875rem 2.25rem;
        height: 4.5rem;
    }
    .file-input{
        cursor: pointer;
        &:after{
            content: '+';
            background-color: $primary;
            color: $white;
            border-radius: 50%;
            width: 2.25rem;
            height: 2.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.25rem;
            font-weight: 700;
        }
        .file-name{
            width: calc(100% - 2.5rem);
        }
        input[type="file"]{
            opacity: 0;
            visibility: hidden;
        }
    }
    select{
        background-image: url('../../images/select-bg.png');
        background-position: right 1.5rem center;
        background-size: 2.25rem 2.25rem;
    }
    textarea{
        height: 12.5rem !important;
    }
    .btn{
        font-size: 0.75rem;
        font-weight: 700;
        min-width: 11.625rem;
        padding: 1.25rem;
        border-radius: $border-radius-lg;
    }
}
@include media-breakpoint-up(xl) {
    .contact-box{
        h2{
            font-size: 2.188rem;
            margin-bottom: 1.875rem;
        }
        .form-group{
            margin-bottom: 1.875rem;
        }
        .form-control, .form-select, .file-input{
            padding: 2.25rem 1.875rem 2.25rem 3.5rem;
            height: 5.438rem;
        }
        select{
            background-position: right 1.875rem center;
        }
    }
}
