.single-product{
    border-radius: $border-radius-lg;
    padding: 1.125rem;
    height: 27.188rem;
    .icons{
        top: 1.125rem;
        left: 1.125rem;
        .icon{
            width: 2.25rem;
            height: 2.25rem;
            background-color: $gray-100;
            margin-bottom: 0.625rem;
            color: $dark;
            &:hover, &:not(.collapsed){
                background-color: $primary;
                color: $white;
            }
        }
    }
    .image{
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        height: 14.563rem;
        width: 100%;
        margin-top: 1.063rem;
        margin-bottom: 2.25rem;
    }
    .code{
        font-size: 0.75rem;
        margin-bottom: 0.75rem;
    }
    h3{
        font-size: 1rem;
        font-weight: 800;
        margin-bottom: 2.25rem;
    }
    .bottom-info{
        p{
            color: $gray-500;
            font-size: 0.75rem;
            &.price{
                font-weight: 800;
                color: $primary;
                font-size: 1rem;
            }
        }
    }
    .collapse-wrap {
        position: absolute;
        top: calc(100% - 1.5rem);
        left: 0;
        right: 0;
        border-bottom-left-radius: 1.75rem;
        border-bottom-right-radius: 1.75rem;
        filter: drop-shadow(0 0.625rem 0.313rem rgba(0, 0, 0, 0.07));
        padding: 0 1.125rem;
        z-index: 9000;
        .collapse-item {
            .content {
                padding: 1.125rem 0;
                .additional-info {
                    border-top: 0.063rem solid $gray-200;
                    margin-top: 1.875rem;
                    padding-top: 1.875rem;
                    p {
                        font-size: 1rem;
                        line-height: 1.2;
                        margin-bottom: 0.75rem;
                    }
                }
            }
        }
    }
    &.promo{
        min-height: 27.188rem;
        padding-bottom: 2.5rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        > a{
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
        .icons{
            z-index: 2;
            .icon{
                background-color: $white;
                &:hover, &:not(.collapsed){
                    background-color: $primary;
                    color: $white;
                }
            }
        }
        h3{
            margin-bottom: 0.625rem;
        }
        .price{
            top: 1.125rem;
            right: 1.25rem;
            line-height: 1;
            padding: 0.5rem 0.625rem;
            border-radius: 1.063rem;
            font-size: 0.75rem;
            strong{
                font-weight: 800;
                font-size: 1rem;
            }
        }
        .promo-badge{
            font-size: 0.75rem;
            font-weight: 800;
            padding: 0.625rem 0.75rem;
            line-height: 1;
            border-radius: 0.875rem;
            letter-spacing: 0.063rem;
        }
    }
}
#sylius-product-variants{
    th{
        font-size: 0.75rem;
        color: $black;
        vertical-align: middle;
        border-bottom: 0;
        font-weight: 400;
        padding: 0 1.25rem 1.25rem;
        &:first-child{
            padding-left: 0;
        }
        &:last-child{
            padding-right: 0;
        }
    }
    td{
        font-size: 0.75rem;
        color: $black;
        font-weight: 800;
        border-bottom: 0.063rem solid $gray-100;
        padding: 1.25rem;
        vertical-align: middle;
        &:first-child{
            padding-left: 0;
        }
        &:last-child{
            padding-right: 0;
        }
    }
    .price{
        border-radius: 0.625rem;
        line-height: 1;
        padding: 0.375rem 0.625rem;
        font-weight: 800;
    }
    .icon{
        width: 2.25rem;
        height: 2.25rem;
        background-color: $gray-100;
        color: $black;
        font-size: 1.125rem;
    }
    tbody{
        border: 0;
    }
    .image-wrap{
        width: 2.5rem;
        height: 2.5rem;
    }
}
@include media-breakpoint-up(md) {
    .single-product{
        &.promo {
            min-height: 0;
        }
    }
}
