.info-box{
    border-radius: 1.625rem;
    padding: 1.875rem 1.5rem;
    z-index: 2;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    .icon{
        width: 3.125rem;
        height: 3.125rem;
        background-color: $gray-100;
        font-size: 1.5rem;
        top: -0.875rem;
        right: 0;
        font-weight: 700;
    }
    h3{
        font-size: 1.5rem;
        margin-bottom: 1.25rem;
    }
    ul{
        li{
            font-size: 1rem;
            font-weight: 300;
            &::marker{
                font-size: 1.313rem;
            }
        }
    }
    &.type-2{
        padding: 1.5rem;
        margin-top: 0;
        margin-bottom: 0;
        max-width: 100%;
        left: 0;
        .icon{
            right: 0;
        }
        .btn{
            font-size: 0.75rem;
            border-radius: $border-radius-lg;
            padding: 1.25rem 2.5rem;
            line-height: 1;
        }
    }
    &.type-3{
        max-width: 100%;
        .icon{
            left: 0;
            right: auto;
        }
        .flex-section{
            margin-bottom: 2.5rem;
        }
        &:not(.auctions){
            left: 0;
        }
        &.auctions{
            max-width: 65%;
        }
    }
}
@include media-breakpoint-up(xl) {
    .info-box {
        margin-top: -8.125rem;
        margin-bottom: -4.5rem;
        max-width: 60%;
        left: 3.375rem;
        padding: 1.875rem 3rem;
        .icon{
            right: -0.875rem;
        }
        h3{
            font-size: 2.188rem;
            margin-bottom: 1.875rem;
        }
        &.type-2{
            padding: 2.625rem;
            transform: translateX(2.625rem);
            .icon{
                left: -0.875rem;
            }
        }
        &.type-3{
            padding: 3rem 2.125rem 2.125rem 3rem;
            .icon{
                left: -1.25rem;
                &.right{
                    left: auto;
                    right: -1.25rem;
                }
            }
            h3{
                margin-bottom: 3.75rem;
            }
            .icon-img{
                width: 5rem;
                img{
                    margin: 0;
                }
            }
            .icon-number{
                width: 3.75rem;
                height: 3.75rem;
                border: 0.125rem solid $white;
                font-size: 1.875rem;
                font-weight: 700;
            }
            .text{
                width: calc(100% - 8rem);
                p{
                    font-size: 1.125rem;
                }
            }
        }
    }
}
