.sidebar{
    border-radius: $border-radius-lg;
    padding: 1rem 0;
    min-height: 28.25rem;
    .single-item-wrap{
        .single-item{
            padding: 1rem 1.5rem;
            color: $gray-600;
            .icon{
                width: 1.875rem;
                height: 2.5rem;
                font-size: 1.75rem;
                margin-right: 0.875rem;
                img{
                    opacity: 1;
                    visibility: visible;
                    transition: all 0.3s ease-in-out;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    &.active{
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
            p{
                font-size: 0.625rem;
                line-height: 1;
                font-weight: 800;
                letter-spacing: 0.063rem;
            }
            &:after{
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0.625rem 0.625rem 0.625rem 0;
                border-color: transparent $primary transparent transparent;
                opacity: 0;
                visibility: hidden;
                transition: all 0.3s ease-in-out;
            }
            &:hover, &.active, &:not(.collapsed){
                color: $primary;
                .icon{
                    img{
                        opacity: 0;
                        visibility: hidden;
                        &.active{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
                &:after{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        .sub-menu{
            width: 100%;
            padding: 1rem 1.5rem;
            z-index: 10;
            > div{
                width: 100%;
                > div{
                    width: 100%;
                    > h3{
                        margin-bottom: 1rem;
                    }
                    > ul{
                        > li{
                            margin-bottom: 1.5rem;
                        }
                    }
                }
            }
            h3{
                font-size: 0.75rem;
                font-weight: 800;
                letter-spacing: 0.125rem;
                padding-bottom: 0.5rem;
                line-height: 1;
                margin-bottom: 0.5rem;
                &:after{
                    content: '';
                    display: block;
                    width: 2.5rem;
                    height: 0.125rem;
                    background: $primary;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
            h4{
                margin-bottom: 0;
            }
            ul{
                margin-bottom: 1.5rem;
                li{
                    a{
                        padding: 0.25rem 0;
                        font-size: 0.75rem;
                        color: $gray-600;
                        font-weight: 800;
                        letter-spacing: 0.063rem;
                        display: block;
                    }
                }
            }
            .all_cat_btn{
                position: absolute;
                bottom: 1rem;
                right: 1rem;
                font-size: 0.75rem;
                color: $white;
                font-weight: 800;
                letter-spacing: 0.063rem;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .sidebar{
        .single-item-wrap{
            .collapse, .collapsing{
                height: auto !important;
                display: block !important;
            }
            .sub-menu{
                position: absolute;
                top: 0;
                width: 75%;
                right: 0;
                padding: 3.125rem 0;
                border-radius: $border-radius-lg;
                filter: drop-shadow(0 0.625rem 0.313rem rgba(0, 0, 0, 0.07));
                opacity: 0;
                visibility: hidden;
                transition: all 0.3s ease-in-out;
                > div{
                    width: 25%;
                    > div{
                        > h3{
                            padding-bottom: 0.875rem;
                            margin-bottom: 2.813rem;
                        }
                        > ul{
                            > li{
                                margin-bottom: 2.5rem;
                            }
                        }
                    }
                }
                ul{
                    margin-bottom: 0;
                }
            }
            .collapse{
                &.show{
                    .sub-menu {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .sidebar{
        padding: 1.5rem 0;
        min-height: 38.875rem;
        .single-item-wrap{
            .sub-menu{
                padding: 3.125rem;
                .all_cat_btn{
                    right: 3.125rem;
                }
            }
            .single-item{
                padding: 1.5rem 3.5rem;
                .icon{
                    width: 2.5rem;
                    height: 3.125rem;
                    font-size: 2.125rem;
                    margin-right: 1.25rem;
                }
                p {
                    font-size: 0.75rem;
                }
            }
        }
    }
}
